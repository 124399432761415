<div>
  <div class="space-y-12">
    <form [formGroup]="legalLinksForm" (ngSubmit)="onSubmit()">
      <div class="space-y-12">
        <div class="border-b border-gray-900/10 pb-12">
          <h2 class="text-base/7 font-semibold text-gray-900">Legal Links</h2>
          <p class="mt-1 text-sm/6 text-gray-600">These links provide users with quick access to important policies and
            legal information, such as the Privacy Policy, Terms of Service, and Cookie Policy. They ensure
            transparency, build trust, and help comply with legal regulations.</p>

          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="sm:col-span-full">
              <label for="privacyPolicy" class="block text-sm/6 font-medium text-gray-900">Privacy Policy</label>
              <div class="mt-2">
                <input placeholder="Enter Privacy Policy URL" type="text" formControlName="privacyPolicy"
                  id="privacyPolicy"
                  class="block w-full rounded-md border-0 bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6">
                <div *ngIf="legalLinksForm.get('privacyPolicy').invalid && (legalLinksForm.get('privacyPolicy').touched || legalLinksForm.get('privacyPolicy').dirty)" class="text-red-600 text-sm mt-2 font-medium">
                  <div *ngIf="legalLinksForm.get('privacyPolicy').errors.required">Privacy Policy URL is required.</div>
                  <div *ngIf="legalLinksForm.get('privacyPolicy').errors.invalidUrl">Privacy Policy URL must start with 'https://'.</div>
                </div>
              </div>
            </div>
            <div class="sm:col-span-full">
              <label for="termsOfService" class="block text-sm/6 font-medium text-gray-900">Terms of Service</label>
              <div class="mt-2">
                <input placeholder="Enter Terms of Service URL" type="text" formControlName="termsOfService"
                  id="termsOfService"
                  class="block w-full rounded-md border-0 bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6">
                <div *ngIf="legalLinksForm.get('termsOfService').invalid && (legalLinksForm.get('termsOfService').touched || legalLinksForm.get('termsOfService').dirty)" class="text-red-600 text-sm mt-2 font-medium">
                  <div *ngIf="legalLinksForm.get('termsOfService').errors.required">Terms of Service URL is required.</div>
                  <div *ngIf="legalLinksForm.get('termsOfService').errors.invalidUrl">Terms of Service URL must start with 'https://'.</div>
                </div>
              </div>
            </div>
            <div class="sm:col-span-full">
              <label for="refundPolicy" class="block text-sm/6 font-medium text-gray-900">Refund Policy</label>
              <div class="mt-2">
                <input placeholder="Enter Refund Policy URL" type="text" formControlName="refundPolicy"
                  id="refundPolicy"
                  class="block w-full rounded-md border-0 bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6">
                <div *ngIf="legalLinksForm.get('refundPolicy').invalid && (legalLinksForm.get('refundPolicy').touched || legalLinksForm.get('refundPolicy').dirty)" class="text-red-600 text-sm mt-2 font-medium">
                  <div *ngIf="legalLinksForm.get('refundPolicy').errors.required">Refund Policy URL is required.</div>
                  <div *ngIf="legalLinksForm.get('refundPolicy').errors.invalidUrl">Refund Policy URL must start with 'https://'.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end">
          <button [disabled]="!legalLinksForm.valid" type="submit"
            class="inline-flex w-full items-center justify-center rounded-md bg-main px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main sm:col-start-2">
            <svg *ngIf="isSaving" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
              </circle>
              <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
              </path>
            </svg>
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
