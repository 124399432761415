import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { SharedModule } from '../shared/shared.module';
import { EditFormComponent } from './edit-form/edit-form.component';
import { FormBuilderComponent } from './edit-form/form-builder/form-builder.component';
import { DefaultTemplateComponent } from './edit-form/form-configuration/default-template/default-template.component';
import { FormConfigurationComponent } from './edit-form/form-configuration/form-configuration.component';
import { RsvpComponent } from './edit-form/form-configuration/rsvp/rsvp.component';
import { StatusComponent } from './edit-form/form-configuration/status/status.component';
import { WaitingListComponent } from './edit-form/form-configuration/waiting-list/waiting-list.component';
import { FormDetailsComponent } from './edit-form/form-details/form-details.component';
import { FormTicketsComponent } from './edit-form/form-tickets/form-tickets.component';
import { FormsComponent } from './forms/forms.component';
import { RegistrationComponent } from './registration.component';

import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { BadgesComponent } from './badges/badges.component';
import { BuilderDebugComponent } from './edit-form/form-builder/builder-debug/builder-debug.component';
import { NewTicketComponent } from './edit-form/form-tickets/new-ticket/new-ticket.component';
import { HorizontalComponent } from './edit-form/form-tickets/view-ticket/horizontal/horizontal.component';
import { VerticalComponent } from './edit-form/form-tickets/view-ticket/vertical/vertical.component';
import { ViewTicketComponent } from './edit-form/form-tickets/view-ticket/view-ticket.component';
import { FormComponent } from './forms/form/form.component';
import { CustomFieldComponent } from './forms/new-form/custom-field/custom-field.component';
import { FormTutorialComponent } from './forms/new-form/form-tutorial/form-tutorial.component';
import { InputSelectorComponent } from './forms/new-form/input-selector/input-selector.component';
import { NewFormComponent } from './forms/new-form/new-form.component';
import { PredefinedFieldComponent } from './forms/new-form/predefined-field/predefined-field.component';
import { MenuComponent } from './menu/menu.component';
import { TicketsComponent } from './tickets/tickets.component';
import { CheckpointsComponent } from './checkpoints/checkpoints.component';
import { BadgeThemeComponent } from './badge-theme/badge-theme.component';
import { NewPaymentGatewayComponent } from './edit-form/form-tickets/new-payment-gateway/new-payment-gateway.component';
import { FormQuestionComponent } from './edit-form/form-question/form-question.component';
import { QuestionComponent } from './edit-form/form-question/question/question.component';
import { NewQuestionComponent } from './edit-form/form-question/new-question/new-question.component';
import { FormLegalLinksComponent } from './edit-form/form-legal-links/form-legal-links.component';

@NgModule({
  declarations: [
    RegistrationComponent,
    FormsComponent,
    EditFormComponent,
    FormBuilderComponent,
    FormDetailsComponent,
    FormConfigurationComponent,
    FormTicketsComponent,
    StatusComponent,
    RsvpComponent,
    WaitingListComponent,
    DefaultTemplateComponent,
    BuilderDebugComponent,
    NewTicketComponent,
    ViewTicketComponent,
    HorizontalComponent,
    VerticalComponent,
    TicketsComponent,
    BadgesComponent,
    FormComponent,
    NewFormComponent,
    FormTutorialComponent,
    InputSelectorComponent,
    PredefinedFieldComponent,
    CustomFieldComponent,
    MenuComponent,
    CheckpointsComponent,
    BadgeThemeComponent,
    NewPaymentGatewayComponent,
    FormQuestionComponent,
    QuestionComponent,
    NewQuestionComponent,
    FormLegalLinksComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    NzDatePickerModule,
    DynamicFormModule
  ]
})
export class RegistrationModule { }
