import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Form } from 'src/app/shared/model/form/form.model';
import { FormService } from 'src/app/shared/service/form.service';

@Component({
  selector: 'new-question',
  templateUrl: './new-question.component.html',
  styleUrl: './new-question.component.scss',
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class NewQuestionComponent implements OnInit {
  @Input('form') form: Form;
  @Output('afterCreate') afterCreate: EventEmitter<Form> = new EventEmitter();
  isModal = false;
  isSaving = false;
  questionform: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private formService: FormService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.questionform = this.formBuilder.group({
      question: ['', [Validators.required, Validators.maxLength(350)]],
      answer: ['', [Validators.required, Validators.maxLength(750)]],
    });
  }

  onSubmit() {
    if (this.questionform.invalid) return;

    this.isSaving = true;

    const values = this.questionform.getRawValue();

    this.formService.addQuestion(values.question, values.answer, this.form).subscribe({
      next: result => {
        this.form.faqs = result.faqs;
        this.afterCreate.emit(result);
        this.isSaving = false;
      },
      error: error => {
        this.isSaving = false;
      }
    });

    this.isModal = false;
  }

  toggleModal() {
    this.isModal = !this.isModal;
  }
}
