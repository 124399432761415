import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BriefcaseBusiness, LucideAngularModule } from 'lucide-angular';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LucideAngularModule.pick({ BriefcaseBusiness })
  ],
  exports: [LucideAngularModule]
})
export class IconModule { }
