<div class="pt-6 w-full">
  <div>
    <dt class="w-full flex  gap-x-2 justify-start items-center">
      <button (click)="remove()" type="button" class="rounded-full p-1.5 text-main2 hover:text-main2/70">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
        </svg>
      </button>
      <button (click)="toggle()" type="button" class="flex w-full items-start justify-between text-left text-gray-700"
        aria-controls="faq-0" aria-expanded="false">
        <span class="text-base/7 font-semibold">{{ question.question }}</span>
        <span class="ml-6 flex h-7 items-center">
          @if (isExpanded ) {
          <svg class="size-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            aria-hidden="true" data-slot="icon">
            <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
          </svg>
          } @else {
          <svg class="size-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            aria-hidden="true" data-slot="icon">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
          </svg>
          }
        </span>
      </button>
    </dt>
    <dd *ngIf="isExpanded" class="mt-2 pr-11" id="faq-0">
      <p class="text-base/7 text-gray-600">{{ question.answer }}</p>
    </dd>
  </div>
</div>
