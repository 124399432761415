<div class="w-full px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="text-base font-semibold leading-6 text-slate-900 inline-flex justify-start items-center">
        Tickets
      </h1>
      <p class="mt-2 text-sm text-slate-700">A list of all the users in your account including their name, title,
        email and role.</p>
    </div>
    <div class="mt-4 sm:ml-16 sm:mt-0 flex justify-center gap-x-2">
      <new-payment-gateway></new-payment-gateway>
      <new-ticket (afterSubmit)="onNewTicketCreated($event)"></new-ticket>
    </div>
  </div>
  <div class="-mx-4 mt-8 sm:-mx-0">
    <table class="min-w-full divide-y divide-slate-300">
      <thead>
        <tr>
          <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-900 sm:pl-0">
            Name
          </th>
          <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-slate-900 lg:table-cell">
            Price
          </th>
          <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-slate-900 sm:table-cell">
            Quantity
          </th>
          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-slate-900">
            Most Popular
          </th>
          <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
            <span class="sr-only">Edit</span>
            <span class="sr-only">Remove</span>
            <span class="sr-only">View</span>
          </th>
        </tr>
      </thead>
      <tbody class="divide-y divide-slate-200 bg-white">
        <tr *ngFor="let ticket of tickets">
          <td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-slate-900 sm:w-auto sm:max-w-none sm:pl-0">
            {{ ticket.name }}
            <dl class="font-normal lg:hidden">
              <dt class="sr-only">Price</dt>
              <dd class="mt-1 truncate text-slate-700">Price: {{ getCurrency(ticket) }}{{ ticket.regular_price}}
              </dd>
              <dt class="sr-only">Quantity</dt>
              <dd class="mt-1 truncate text-slate-700">Quantity: {{ ticket.stock_quantity}}</dd>
            </dl>
          </td>
          <td class="hidden px-3 py-4 text-sm text-slate-500 lg:table-cell">{{ getCurrency(ticket) }}{{
            ticket.regular_price}}</td>
          <td class="hidden px-3 py-4 text-sm text-slate-500 sm:table-cell">{{ ticket.stock_quantity }}</td>
          <td class="px-3 py-4 text-sm text-slate-500">
            <span *ngIf="!isMostPopular(ticket)"
              class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">No</span>
            <span *ngIf="isMostPopular(ticket)"
              class="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">Yes</span>
          </td>
          <td class="py-4 pl-3 pr-4 text-right text-sm font-medium flex justify-start items-center gap-x-2 sm:pr-0">
            <view-ticket [ticket]="ticket"></view-ticket>
            <new-ticket (afterSubmit)="onNewTicketCreated($event)" [editTicket]="ticket"></new-ticket>
            <a (click)="toggleConfirm(ticket)" class="text-slate-700 hover:text-main">
              <svg *ngIf="selectedTicket?.id == ticket.id && removing" class="animate-spin h-5 w-5 text-main"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                </circle>
                <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
              </svg>
              <span *ngIf="selectedTicket?.id != ticket.id && !removing">

                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                  stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                </svg>

                <span class="sr-only">, {{ ticket.name }}</span>
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mt-5 flex justify-center items-center">
      <svg *ngIf="loading" class="animate-spin ml-3 h-5 w-5 text-main" xmlns="http://www.w3.org/2000/svg" fill="none"
        viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
        </circle>
        <path class="opacity-75" fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
        </path>
      </svg>
    </div>
  </div>
</div>

<danger-dialog [title]="'Remove Ticket'" [subTitle]="'Are you sure you want to remove this ticket?'"
  [isModal]="isModalRemove" (onClose)="closeDialog($event)"></danger-dialog>
