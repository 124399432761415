import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormQuestion } from 'src/app/shared/model/form/form.model';

@Component({
  selector: 'question',
  templateUrl: './question.component.html',
  styleUrl: './question.component.scss'
})
export class QuestionComponent {
  @Input('question') question: FormQuestion;
  @Output('onRemove') onRemove: EventEmitter<FormQuestion> = new EventEmitter();
  isExpanded: boolean = false;

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  remove() {
    this.onRemove.emit(this.question);
  }

}
