export class Setting {
  public constructor(
    public url: string = '',
    public dataRest: string = '',
    public maxTag: number = 0,
    public maxImageSizeMo: number = 15,
    public maxFileSizeMo: number = 0,
    public dateAPIFormat: string = "yyyy-MM-dd'T'HH:mm",
  ) { }
}
