<div>
  <div class="space-y-12">
    <div class="grid grid-cols-1 gap-x-8 gap-y-10 pb-12 md:grid-cols-1">
      <div>
        <div class="flex justify-between items-start">
          <h2 class="text-2xl font-semibold leading-7 text-gray-700">Frequently Asked Questions</h2>
          <new-question (afterCreate)="onCreate(form)" [form]="form"></new-question>
        </div>
        <p class="mt-3.5 text-base leading-6 text-gray-600">Add questions and answers that attendees commonly ask about
          your
          event. These FAQs will appear on the payment page to help clarify details, reduce queries, and improve the
          attendee experience.</p>
      </div>
      <div class="mx-auto w-full">
        @if (form.faqs.length == 0) {
        <div class="border-l-8 border-blue-400 bg-blue-50 p-4 rounded-md">
          <div class="flex">
            <div class="shrink-0">
              <svg class="size-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                data-slot="icon">
                <path fill-rule="evenodd"
                  d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495ZM10 5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 10 5Zm0 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                  clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <p class="text-sm text-blue-700 font-medium">
                You have no questions created.
                <a class="ml-1.5 font-semibold text-blue-700 hover:text-blue-600">Please start by craeting a new
                  question.</a>
              </p>
            </div>
          </div>
        </div>
        }

        <div class="divide-y divide-gray-900/10 w-full">
          <dl class="space-y-6 divide-y divide-gray-900/10 w-full">
            @for (question of form.faqs ; track $index) {
            <question (onRemove)="remove(question)" [question]="question"></question>
            }
          </dl>
        </div>
      </div>
    </div>
  </div>
</div>
