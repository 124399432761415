import { Component, Input } from '@angular/core';
import { Ticket } from 'src/app/shared/model/ticket/ticket.model';

@Component({
  selector: 'view-horizontal',
  templateUrl: './horizontal.component.html',
  styleUrls: ['./horizontal.component.scss']
})
export class HorizontalComponent {
  @Input('ticket') ticket: Ticket;

  get isMostPopular() {
    const index: number = this.ticket.meta_data.findIndex(data => data.key == 'isMostPopular');
    if (index == -1) return false;

    return this.ticket.meta_data[index].value == '1'
  }

  getCurrency() {
    return this.ticket.meta_data.find(c => c.key == 'currency').value;
  }

  get description() {
    return this.ticket?.description.replace('<p>', '').replace('</p>', '') || '';
  }

  get whatsIncluded(): string[] {
    const index: number = this.ticket.meta_data.findIndex(data => data.key == 'includes');
    if (index == -1) return [];

    return this.ticket.meta_data[index].value;
  }

}
