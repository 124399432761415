import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Event } from '../../model/event/event.model';
import { PaymentGateway, WaveConfig, WaveConfigRequest } from '../../model/event/wave-config.request';
import { User } from '../../model/user.model';
import { EventService } from '../../service/event.service';
import { LastUpdatesTrackerService } from '../../service/last-updates-tracker.service';
import { PaymentGatewayConfigService } from '../../service/payment-gateway-config.service';
import { UserService } from '../../service/session.service';
import { FloatingBannersService, Message } from '../floating-notification/floating-banners.service';

@Component({
  selector: 'configure-paymentgateway',
  templateUrl: './configure-paymentgateway.component.html',
  styleUrl: './configure-paymentgateway.component.scss'
})
export class ConfigurePaymentgatewayComponent implements OnInit, OnDestroy {
  @Output('onClose') onClose: EventEmitter<boolean> = new EventEmitter();
  @Output('onSave') onSave: EventEmitter<boolean> = new EventEmitter();

  event: Event;
  user: User;
  eventSub: Subscription;
  userSub: Subscription;
  saving: boolean = false;
  gateways: PaymentGateway[] = [];
  selected: PaymentGateway;
  waveConfig: WaveConfig;
  currencies: string[] = [];
  form: FormGroup;
  isReady: boolean = false;

  constructor(
    private eventService: EventService,
    private updateTracker: LastUpdatesTrackerService,
    private pgatewayService: PaymentGatewayConfigService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private floatingBannersService: FloatingBannersService
  ) { }

  ngOnInit(): void {
    this.userSub = this.userService.session.subscribe({
      next: user => {
        this.user = user;
        this.buildForm();
      }
    });

    this.eventSub = this.eventService.selectedEvent.subscribe({
      next: event => {
        this.event = event;
      },
    });
    this.gateways = this.pgatewayService.getGatewayOptions();
    this.selected = this.gateways[0];
  }

  ngOnDestroy(): void {
    if (this.eventSub) this.eventSub.unsubscribe();
    if (this.userSub) this.userSub.unsubscribe();
  }

  trackUpdate(menu: string) {
    this.updateTracker.newUpdate(menu);
  }

  buildForm() {
    this.pgatewayService
      .getWaveConfig()
      .subscribe({
        next: (wave) => {
          this.waveConfig = wave;

          this.currencies = wave?.possibleCurrencies || [];
          const contactName: string = wave != null ? wave.contactName : `${this.user.firstName || ''} ${this.user.lastName || ''}`;
          const contactPhone: string = wave != null ? wave.contactPhone : `${this.user.phoneNumber || ''}`;
          const waveContact: string = wave != null ? wave.waveContact : `${this.user.phoneNumber || ''}`;

          this.form = this.formBuilder.group({
            contactName: [contactName, [Validators.required]],
            contactPhone: [contactPhone, [Validators.required]],
            waveContact: [waveContact, [Validators.required]],
          });

          this.isReady = true;

        },
        error: err => {
        }
      });
  }

  onSelect(currency: string) {
    if (this.currencies.includes(currency)) {
      this.currencies = this.currencies.filter(cur => cur != currency);
    } else {
      this.currencies.push(currency);
    }
  }

  save() {
    if (this.form.invalid || this.currencies.length == 0) return;

    this.saving = true;
    const values = this.form.getRawValue();

    const request: WaveConfigRequest = {
      confId: this.waveConfig ? this.waveConfig.id : '',
      contactName: values.contactName,
      contactPhone: values.contactPhone,
      waveContact: values.waveContact,
      waveMerchantId: values.waveMerchantId,
      eventId: this.eventService.selectedEvent.value.id,
      currencies: this.currencies
    };

    this.pgatewayService
      .configureWave(request)
      .subscribe({
        next: success => {
          this.saving = false;
          const msg: Message = {
            title: 'Payment Gateway',
            text: 'Your configuration has been saved',
            bgColor: 'bg-lime-500',
            textColor: 'text-white',
            isCloasable: false
          }
          this.floatingBannersService.display(msg);
          this.onSave.emit(true);
        },
        error: error => {
          this.saving = false;
        }
      })
  }

  cancel() {
    this.onClose.emit(true);
  }

  isChecked(option: string) {
    return this.currencies.includes(option);
  }
}
