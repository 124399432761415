import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';
import { Event } from '../model/event/event.model';
import { EventService } from '../service/event.service';

export const eventResolver: ResolveFn<Observable<Event>> = (route, state) => {
  const eventService: EventService = inject(EventService);
  const eventId: string = route.paramMap.get('eventId');
  return eventService.getEventById(eventId);
};
