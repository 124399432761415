import { Ticket } from "../ticket/ticket.model";
import { FormConfiguration } from "./form-configuration.model";
import { FormDefinition } from "./form-definition.model";
import { LegalLinks } from "./legal-link.model";

export interface FormQuestion {
  question: string;
  answer: string;
}
export class Form {
  public constructor(
    public id: string = '',
    public formDefinition: FormDefinition = new FormDefinition(),
    public formConfiguration: FormConfiguration = new FormConfiguration(),
    public startDate: string = '',
    public endDate: string = '',
    public url: string = '',
    public name: string = '',
    public purpose: string = '',
    public tickets: Ticket[] = [],
    public loadingTickets: boolean = false,
    public legalLinks: LegalLinks = { privacyPolicy: '', refundPolicy: '', termsOfService: '' },
    public faqs: FormQuestion[] = [],
  ) { }
}
