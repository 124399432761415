import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { EditorModule } from 'primeng/editor';
import { ConfigurePaymentgatewayComponent } from './components/configure-paymentgateway/configure-paymentgateway.component';
import { DangerDialogComponent } from './components/dialogs/danger-dialog/danger-dialog.component';
import { FloatingBannersComponent } from './components/floating-banners/floating-banners.component';
import { FloatingNotificationComponent } from './components/floating-notification/floating-notification.component';
import { SelectMenuComponent } from './components/inputs/select-menu/select-menu.component';
import { NotificationComponent } from './components/notifications/notification/notification.component';
import { RichTextInputComponent } from './components/rich-text-input/rich-text-input.component';
import { ImagePipe } from './pipes/image.pipe';

@NgModule({
  declarations: [
    SelectMenuComponent,
    ImagePipe,
    NotificationComponent,
    DangerDialogComponent,
    FloatingBannersComponent,
    FloatingNotificationComponent,
    RichTextInputComponent,
    ConfigurePaymentgatewayComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    ToastrModule.forRoot(),
  ],
  exports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SelectMenuComponent,
    NotificationComponent,
    ImagePipe,
    DangerDialogComponent,
    FloatingBannersComponent,
    FloatingNotificationComponent,
    RichTextInputComponent,
    ConfigurePaymentgatewayComponent
  ],
  providers: [
    CookieService
  ]
})
export class SharedModule { }
