import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { Form, FormQuestion } from '../model/form/form.model';
import { LegalLinks } from '../model/form/legal-link.model';
import { AppSettingService } from './app-setting.service';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  constructor(
    private appSettings: AppSettingService,
    private httpClient: HttpClient,
    private errorService: ErrorService
  ) { }

  addQuestion(question: string, answer: string, form: Form) {
    const formId: string = form.id;
    const endPoint: string = `${this.appSettings.settings.url}/forms-v2/${formId}`;

    const api = this.httpClient.patch<Form>(endPoint, {
      'faqs': [
        { question: question, answer: answer },
        ...form.faqs,
      ]
    });

    return api.pipe(
      catchError(error => this.errorService.handleError(error, null, true)),
    );
  }

  removeQuestion(question: FormQuestion, form: Form) {
    const formId: string = form.id;
    const endPoint: string = `${this.appSettings.settings.url}/forms-v2/${formId}`;

    const api = this.httpClient.patch<Form>(endPoint, {
      'faqs': [
        ...form.faqs.filter(quest => quest.question != question.question),
      ]
    });

    return api.pipe(
      catchError(error => this.errorService.handleError(error, null, true)),
    );
  }

  saveLegalLinks(legalLinks: LegalLinks, form: Form) {
    const formId: string = form.id;
    const endPoint: string = `${this.appSettings.settings.url}/forms-v2/${formId}`;

    const api = this.httpClient.patch<Form>(endPoint, {
      'legalLinks': legalLinks,
    });

    return api.pipe(
      catchError(error => this.errorService.handleError(error, null, true)),
    );
  }
}
