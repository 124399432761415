<nav class="border-b border-gray-200 bg-white">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="flex h-16 justify-between">
            <div class="hidden md:flex">
                <div class="flex flex-shrink-0 gap-x-1.5 items-center">
                    <img class="block h-8 w-auto" src="assets/logo/logo.png" alt="Appsaya">
                    <span class="text-gray-500 text-xs font-light font-text">Manager</span>
                </div>
            </div>
            <div class="relative w-full flex justify-center items-center">
                <a *ngIf="selectedOrg| async; let org" type="button" (click)="toggleFlyoutMenu()"
                    class="inline-flex items-center gap-x-2.5" aria-expanded="false">
                    <span class="text-xs text-gray-500 font-text">Owner of</span>
                   <span class="orgname">{{ org.name }}</span>  <!-- 20px -->
                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                            clip-rule="evenodd" />
                    </svg>
                </a>
            </div>
            <div @opacityTranslateXY *ngIf="isFlyoutMenu"
                class="absolute left-1/2 z-10 mt-20 flex w-screen max-w-max -translate-x-1/2 px-4">
                <div
                    class="w-screen max-w-md flex-auto overflow-hidden rounded-lg bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                    <div class="p-1.5">
                        <div *ngFor="let organisation of organisations"
                            class="group relative flex items-center rounded-lg p-1.5 hover:bg-gray-100">
                            <div
                                class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg">
                                <svg *ngIf="(selectedOrg | async)?.id == organisation.id" class="h-5 w-5 fill-main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                    <path fill-rule="evenodd"
                                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <div>
                                <a (click)="onChangeOrganisation(organisation)"
                                    class="text-base font-normal text-slate-600 font-text">
                                    {{ organisation.name }}
                                    <span class="absolute inset-0"></span>
                                </a>
                                <p *ngIf="organisation.description" class="mt-1 text-gray-600 font-text line-clamp-1">{{organisation.description }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 divide-x divide-gray-900/5 bg-gray-50">
                        <a (click)="togglePortalFlow()"
                            class="flex items-center font-text justify-center gap-x-2.5 p-3 font-normal text-slate-700 hover:bg-gray-100">
                            <svg class="h-5 w-5 flex-none text-slate-400" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24" fill="currentColor">
                                <path fill-rule="evenodd"
                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                                    clip-rule="evenodd" />
                            </svg>
                            New Organisation
                        </a>
                    </div>
                </div>
            </div>
            <div *ngIf="isFlyoutMenu" (click)="toggleFlyoutMenu()" class="fixed inset-0"></div>
            <div class="ml-6 flex items-center">
                <div class="relative ml-3">
                    <div>
                        <button type="button" (click)="toggleMenu()"
                            class="h-8 w-8 flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2"
                            id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                            <span class="sr-only">Open user menu</span>
                            <img class="h-8 w-8 object-cover rounded-full" src="assets/logo/profile_logo.jpg" alt>
                        </button>
                    </div>
                    <div @opacityScale *ngIf="isMenu"
                        class="profile-menu absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">

                        <!-- <a class="block px-4 py-2 text-sm text-slate-700" role="menuitem" tabindex="-1"
                            id="user-menu-item-0">
                            Your Profile
                        </a>

                        <a class="block px-4 py-2 text-sm text-slate-700" role="menuitem" tabindex="-1"
                            id="user-menu-item-1">
                            Settings
                        </a> -->

                        <a (click)="logout()" class="block px-4 py-2 text-sm text-slate-700" role="menuitem"
                            tabindex="-1" id="user-menu-item-2">
                            Sign out
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>