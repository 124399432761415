<button (click)="toggleModal()" type="button"
  class="block rounded-md bg-main px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
  PayGateway
</button>

<div @opacity *ngIf="isModal" class="relative z-[999]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-[999] overflow-y-auto">
    <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
      <div @opacityTranslateY *ngIf="isModal"
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6">
        <configure-paymentgateway (onClose)="close($event)" (onSave)="onSave($event)"></configure-paymentgateway>
      </div>
    </div>
  </div>
</div>
