import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, tap } from 'rxjs';
import { NotificationService } from '../components/notifications/notification.service';
import { NotificationType } from '../model/enum/notification-type.enum';
import { User } from '../model/user.model';
import { Profile } from '../model/user/profile.model';
import { AppSettingService } from './app-setting.service';
import { ErrorService } from './error.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class AttendeesService {
  attendees: BehaviorSubject<Profile[]> = new BehaviorSubject<Profile[]>([]);
  searchResult: BehaviorSubject<Profile[]> = new BehaviorSubject<Profile[]>([]);
  searching: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  totalAttendees: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  oldKeyword: string = '';

  page: number = 0;
  size: number = 100;
  totalElements: number = 0;
  totalPages: number = 0;
  isFirst: boolean = false;
  isLast: boolean = false;

  pageSearch: number = 0;
  sizeSearch: number = 100;
  totalElementsSearch: number = 0;
  totalPagesSearch: number = 0;
  isFirstSearch: boolean = false;
  isLastSearch: boolean = false;


  constructor(
    private appSettings: AppSettingService,
    private eventService: EventService,
    private httpClient: HttpClient,
    private errorService: ErrorService,
    private notification: NotificationService
  ) { }

  getUserDetails(profileId: string) {
    const url: string = `${this.appSettings.settings.url}/profile/user-details?profileId=${profileId}`
    const api$ = this.httpClient.get<User>(url);

    return api$.pipe(
      catchError(error => { console.log(error); return error; }),
    )
  }

  fetchAttendees() {
    this.searchStatus.next(false);
    const eventId: string = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSettings.settings.url}/profile/get-attendees?eventId=${eventId}&page=${this.page}&size=${this.size}&sort=createdAt,desc`
    const api$ = this.httpClient.get(url);

    this.searching.next(true);

    api$
      .pipe(
        map((result: any) => {
          this.isFirst = result.first;
          this.isLast = result.last;
          this.totalElements = result.totalElements;
          this.totalAttendees.next(this.totalElements);
          this.totalPages = result.totalPages;
          return result.content;
        }),
        tap((result: Profile[]) => {
          result = result.filter(data => !this.profileExistInResults(data.id));
          let temp: Profile[] = this.attendees.value;
          temp.push(...result);
          this.attendees.next(temp);
          temp = [];
        }),
        tap(_ => this.searching.next(false)),
        catchError(error => {
          this.searching.next(false);
          return this.errorService.handleError(error, { title: 'Action failed', message: 'We could not load the list of attendees' }, true);
        }),
      )
      .subscribe();
  }

  searchForAttendee(keyword: string) {
    keyword = keyword.trim();
    this.searchStatus.next(true);

    if (keyword != this.oldKeyword) {
      this.oldKeyword = keyword;
      this.resetSearch();
    }
    const eventId: string = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSettings.settings.url}/profile/search-attendee?eventId=${eventId}&keyword=${keyword}&page=${this.page}&size=${this.size}&sort=createdAt,asc`

    const api$ = this.httpClient.get(url);

    return api$
      .pipe(
        map((result: any) => {
          this.isFirst = result.first;
          this.isLast = result.last;
          this.totalElements = result.totalElements;
          this.totalPages = result.totalPages;
          return result.content;
        }),
        tap(result => {
          result = result.filter(data => !this.profileExistInSearchResults(data.id));
          let temp: Profile[] = this.searchResult.value;
          temp.push(...result);
          this.searchResult.next(temp);
          temp = []
        }),
        tap(_ => this.searching.next(false)),
        catchError(error => {
          this.searching.next(false);
          return this.errorService.handleError(error, { title: 'Action failed', message: 'We could not load the list of attendees' }, true);
        }),
      );
  }

  profileExistInSearchResults(profileId) {
    const index = this.searchResult.value.findIndex(profile => profile.id == profileId);
    return index >= 0;
  }

  profileExistInResults(profileId) {
    const index = this.attendees.value.findIndex(profile => profile.id == profileId);
    return index >= 0;
  }

  getAttendeeProfilePicture(userId: string) {
    const url: string = `${this.appSettings.settings.url}/profile/profile-picture?userId=${userId}`;
    const api = this.httpClient.get<any>(url);
    return api.pipe();
  }

  getAttendeeQrCodeURL(profileId: string) {
    const url: string = `${this.appSettings.settings.url}/profile/profile-qrcode-url?profileId=${profileId}`;
    const api = this.httpClient.get<any>(url);
    return api.pipe();
  }

  removeAttendee(attendeeId: string) {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSettings.settings.url}/event-registration/delete?attendeeId=${attendeeId}&eventId=${eventId}`;
    const api = this.httpClient.delete<any>(url);
    return api.pipe(
      tap(_ => {
        const attendees = this.attendees.value.filter(attendee => attendee.id != attendeeId);
        this.attendees.next(attendees);
      }),
      catchError(error => {
        return this.errorService.handleError(error, { title: 'Action failed', message: 'We could not remove this attendee.' }, true);
      }),
    );
  }

  loadMore() {
    if (this.isLast) {
      this.notification.notify('Attendees', 'All attendees have been loaded', NotificationType.Info, 'items-center', 'items-end');
      return;
    }

    this.page = this.page + 1;
    this.fetchAttendees();
  }

  resetSearch(turnoff?: boolean) {
    this.searchResult.next([])
    this.pageSearch = 0;
    this.totalElementsSearch = 0;
    this.totalPagesSearch = 0;
    this.isFirstSearch = false;
    this.isLastSearch = false;
    if (turnoff) this.searchStatus.next(false);
  }

  setEventPresence(profileId: string, checkPoint?: string) {
    let url: string;

    if (checkPoint) {
      url = `${this.appSettings.settings.url}/profile/set-event-presence-withCheckPoint?profileId=${profileId}&checkPoint=${checkPoint}`;
    } else {
      url = `${this.appSettings.settings.url}/profile/set-event-presence?profileId=${profileId}`;
    }

    const api$ = this.httpClient.patch<Profile>(url, {});

    return api$.pipe(
      tap(profile => {
        this.updateCache(profile);
        this.notification.notify('Presence', 'Presence status has been updated.', NotificationType.Success);
      }),
      catchError(error => {
        return this.errorService.handleError(error, { title: 'Action failed', message: 'We could not update the presence of this attendee.' }, true);
      }),
    );
  }

  setWorkshopPresence(profileId: string, workshopId: number) {
    const url: string = `${this.appSettings.settings.url}/profile/set-workshop-presence/${workshopId}?profileId=${profileId}`;
    const api$ = this.httpClient.patch<Profile>(url, {});

    return api$.pipe(
      tap(profile => {
        this.updateCache(profile);
        this.notification.notify('Presence', 'Presence status has been updated.', NotificationType.Success);
      }),
      catchError(error => {
        return this.errorService.handleError(error, { title: 'Action failed', message: 'We could not update the presence of this attendee.' }, true);
      }),
    );
  }

  registerInWorkshop(profileId: string, workshopId: number) {
    const url: string = `${this.appSettings.settings.url}/profile/register-in-workshop/${workshopId}?profileId=${profileId}`;
    const api$ = this.httpClient.patch<Profile>(url, {});

    return api$.pipe(
      tap(profile => {
        this.updateCache(profile);
        this.notification.notify('Workshop', 'Registration is completed.', NotificationType.Success);
      }),
      catchError(error => {
        return this.errorService.handleError(error, { title: 'Action failed', message: 'We could not update the presence of this attendee.' }, true);
      }),
    );
  }

  updateCache(attendee: Profile) {
    let attendees = this.attendees.value;
    let searchResult = this.searchResult.value;
    const indexAttendees = attendees.findIndex(profile => profile.id == attendee.id);
    const indexSearch = searchResult.findIndex(profile => profile.id == attendee.id);

    if (indexAttendees != -1) {
      attendees[indexAttendees] = attendee;
      this.attendees.next(attendees);
    }
    if (indexSearch != -1) {
      searchResult[indexSearch] = attendee;
      this.searchResult.next(searchResult);
    }
  }


}
