<div class="space-y-12 mt-7">
  <div class="mx-auto max-w-4xl lg:text-center">
    <h2 class="text-base font-semibold leading-7 text-main">Payment Gateway</h2>
    <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl line-clamp-2 text-ellipsis">{{
      event.name }}
    </p>
    <p class="mt-6 text-lg leading-8 text-gray-600">Easily integrate and manage multiple payment gateways for seamless
      transactions to enhance your event registration experience with secure and efficient payment solutions.</p>
  </div>
</div>
<div class="mx-auto max-w-5xl mt-24 pb-20">
  <div class="mx-auto lg:flex lg:gap-x-16">
    <aside class="flex overflow-x-auto border-b border-gray-900/5 lg:block lg:w-64 lg:flex-none lg:border-0">
      <nav class="flex-none px-4 sm:px-6 lg:px-0">
        <ul role="list" class="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
          @for (gateway of gateways; track $index) {
          <li>
            <a [ngClass]="{'bg-gray-50 text-main' : selected.label == gateway.label }"
              class="group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-main/70">
              <img class="inline-block h-6 w-6 shrink-0 rounded-md" [src]="gateway.logo" alt="">
              {{ gateway.label }}
            </a>
          </li>
          }
        </ul>
      </nav>
    </aside>
    <main class="px-4 sm:px-6 lg:flex-auto lg:px-0">
      <div class="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div *ngIf="isReady">
          <form [formGroup]="form" (submit)="save()">
            <div class="space-y-12">
              <div class="border-b border-gray-900/10 pb-12">
                <h2 class="text-base font-semibold leading-7 text-gray-900">{{ selected.label }}</h2>
                <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent phone number where you can be
                  contacted.
                </p>

                <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div class="sm:col-span-full">
                    <label for="contactName" class="block text-sm font-medium leading-6 text-gray-900">Contact
                      Name *</label>
                    <div class="mt-2">
                      <input type="text" formControlName="contactName" name="contactName" id="contactName"
                        autocomplete="contactName"
                        class="block w-full rounded-md border-0 px-3.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="sm:col-span-full">
                    <label for="contactPhone" class="block text-sm font-medium leading-6 text-gray-900">Phone
                      Number *</label>
                    <div class="mt-2">
                      <input id="text" formControlName="contactPhone" name="contactPhone" type="contactPhone"
                        class="block w-full rounded-md border-0 px-3.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="sm:col-span-full">
                    <label for="waveContact" class="block text-sm font-medium leading-6 text-gray-900">Mobile Contact
                      to send payment *</label>
                    <div class="mt-2">
                      <input id="text" formControlName="waveContact" name="waveContact" type="text"
                        class="block w-full rounded-md border-0 px-3.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="sm:col-span-full">
                    <fieldset>
                      <div class="border-l-4 border-yellow-400 bg-yellow-50 p-3">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor"
                              aria-hidden="true">
                              <path fill-rule="evenodd"
                                d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                                clip-rule="evenodd" />
                            </svg>
                          </div>
                          <div class="ml-3">
                            <p class="text-sm text-yellow-700 font-semibold">
                              Make sure your wallet support the selected currencies.
                            </p>
                          </div>
                        </div>
                      </div>

                      <legend class="text-sm font-semibold leading-6 text-gray-900">Available currencies for this
                        payment gateway</legend>
                      <div class="mt-6 space-y-6">
                        @for (currency of waveConfig.possibleCurrencies ; track $index) {
                        <div (click)="onSelect(currency)" class="relative flex gap-x-3">
                          <div class="flex h-6 items-center">
                            <input id="xof" name="currencies" type="checkbox" value="{{ currency }}"
                              [checked]="isChecked(currency)"
                              class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600">
                          </div>
                          <div class="text-sm leading-6">
                            <label for="xof" class="font-medium text-gray-900">{{ currency }}</label>
                            <p class="text-gray-500">Payment to your wallet will be in {{ currency }}.</p>
                          </div>
                        </div>
                        }
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-6 flex items-center justify-end gap-x-6">
              <button (click)="cancel()" type="button" class="text-sm w-44 font-semibold leading-6 text-gray-900">Cancel</button>
              <button type="submit"
                class="inline-flex justify-center items-center rounded-md w-44 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <span *ngIf="saving">
                  <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                    fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                    </path>
                  </svg>
                </span>
                Save</button>
            </div>
          </form>
        </div>
      </div>
    </main>
  </div>
</div>
