import { Component, Input } from '@angular/core';
import { Form } from 'src/app/shared/model/form/form.model';
import { FormService } from 'src/app/shared/service/form.service';


@Component({
  selector: 'form-question',
  templateUrl: './form-question.component.html',
  styleUrl: './form-question.component.scss'
})
export class FormQuestionComponent {
  @Input('form') form: Form;

  constructor(private formService: FormService) { }

  onCreate(form: Form) {
    this.form.faqs = form.faqs;
  }

  remove(question) {
    this.formService.removeQuestion(question, this.form).subscribe({
      next: result => {
        this.form.faqs = result.faqs;
      }
    });
  }

}
