<div class="">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-5xl lg:mx-0">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Overview</h2>
        <p class="mt-6 text-lg leading-8 text-gray-600">
         Monitor live statistics of your event.
        </p>
      </div>
        <dl class="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-2 max-w-5/6 p-6 m-auto mx-8">
            <div
            class="mx-auto flex max-w-md flex-col gap-y-4 items-center py-10 px-32 border border-slate-200 rounded-md hover:cursor-pointer">
                <dt class="text-base leading-7 text-gray-600 min-w-48">New users registered</dt>
                <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-8xl">
                    @if (isLoading(countAll)) {
                    <svg class="animate-spin m-auto w-20 h-20 px-2 py-4 text-slate-600"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                    </svg>
                    }@else {
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="m-auto w-20 h-20 px-2 py-4 text-main">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                    </svg>
                    {{ countAttendees }}
                    }
                </dd>
            </div>
            <div
            class="mx-auto flex max-w-md flex-col gap-y-4 items-center py-10 px-32 border border-slate-200 rounded-md hover:cursor-pointer">
                <dt class="text-base leading-7 text-gray-600 min-w-48">Activated accounts</dt>
                <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-8xl">
                    @if (isLoading(countActivatedAccount)) {
                    <svg class="animate-spin m-auto w-20 h-20 px-2 py-4 text-slate-600"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                    </svg>
                    }@else {
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="m-auto w-20 h-20 px-2 py-4 text-main3">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                    </svg>

                    {{ countActivatedAccount }}
                    }
                </dd>
            </div>
        </dl>
        @if (!isLoading(countByDate)) {
        <div
            class="mt-12 items-center  border border-slate-200 rounded-md hover:cursor-pointer p-6 w-5/6 m-auto">
            <div class="px-4 sm:px-6 lg:px-8">
                <div class="sm:flex sm:items-center">
                    <div class="sm:flex-auto">
                        <!-- <h1 class="text-base text-center font-semibold leading-6 text-gray-900 py-5">Number of
                            registrations
                            by day</h1> -->
                        
                    </div>
                </div>
                <div class=" flow-root">
                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="inline-block min-w-full py-2 items-center align-middle ">
                            <table class="min-w-full table-fixed divide-y divide-gray-300 ">
                                <thead>
                                    <tr>
                                        <th scope="col"
                                            class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 w-1/2">
                                            Date</th>
                                        <th scope="col"
                                            class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 w-1/2">
                                            Number
                                            of registrations</th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    @for (item of countByCreationDate; track $index) {
                                    <tr>
                                        <td
                                            class="whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                                            {{ item.day }}</td>
                                        <td
                                            class="whitespace-nowrap text-center px-3 py-4 text-sm font-medium text-gray-900">
                                            {{ item.count }}</td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
    </div>

</div>