<button *ngIf="!editMode" (click)="toggleModal()" type="button"
  class="block rounded-md bg-main3 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-main3/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main3">
  New Ticket
</button>

<a *ngIf="editMode" (click)="toggleModal()" class="text-slate-700 hover:text-main">
  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
    stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round"
      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
  </svg>
  <span class="sr-only">, {{ editTicket.name }}</span>
</a>

<div @opacity *ngIf="isModal" class="relative z-[999]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-[999] overflow-y-auto">
    <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
      <div @opacityTranslateY *ngIf="isModal && !isLoadingPG"
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
        <form [formGroup]="ticketForm" (submit)="onSubmit()">
          <div class="space-y-12">
            <div class>
              <div *ngIf="!editMode">
                <h2 class="text-base font-semibold leading-7 text-slate-900">New Ticket</h2>
                <p class="mt-1 text-sm leading-6 text-slate-600">Create a ticket to add to your form.</p>
              </div>

              <div *ngIf="editMode">
                <h2 class="text-base font-semibold leading-7 text-slate-900">Edit Ticket</h2>
                <p class="mt-1 text-sm leading-6 text-slate-600">Edit this ticket.</p>
              </div>

              @if (waveConfs.length == 0) {
              <div class="mt-4 mb-4 border-l-4 border-yellow-400 bg-yellow-50 p-4">
                <div class="flex">
                  <div class="shrink-0">
                    <svg class="size-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                      data-slot="icon">
                      <path fill-rule="evenodd"
                        d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495ZM10 5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 10 5Zm0 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <p class="text-sm text-yellow-700">
                      You have no payment gateway configured.
                      <br>
                      <a (click)="toggleModal()"
                        class="font-medium text-yellow-700 underline hover:text-yellow-600">Please configure a
                        payment gateway before creating a ticket.</a>
                    </p>
                  </div>
                </div>
              </div>
              <button type="button" (click)="toggleModal()"
                class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
                Close
              </button>
              }

              <div *ngIf="waveConfs.length > 0" class="mt-5 grid grid-cols-1 sm:grid-cols-4 gap-x-2 gap-y-5">
                <div class="col-span-full">
                  <label for="name" class="block text-sm font-medium leading-6 text-gray-900">
                    Package Name
                  </label>
                  <div class="mt-2">
                    <input formControlName="name" type="text" name="name" id="name"
                      placeholder="Freelancer, Startup, Enterprise"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6">
                  </div>
                </div>

                <div class="col-span-full">
                  <label for="description" class="block text-sm font-medium leading-6 text-gray-900">
                    Description
                  </label>
                  <div class="mt-2">
                    <textarea id="description" name="description" formControlName="description" rows="3"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6"></textarea>
                  </div>
                </div>

                <div class="col-span-full">
                  <label for="includes" class="block text-sm font-medium leading-6 text-gray-900">
                    What’s included
                  </label>
                  <div class="mt-2">
                    <textarea id="includes" name="includes" formControlName="includes" rows="3"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6"></textarea>
                  </div>
                  <p class="mt-2 text-sm text-gray-500" id="includes-description">
                    Please separate with a point `<span
                      class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                      . </span>`
                  </p>
                </div>

                <div class="sm:col-span-2">
                  <label for="currency" class="block text-sm font-medium leading-6 text-gray-900">
                    Currency
                  </label>
                  <div class="mt-2">
                    <input type="text" name="currency" id="currency" formControlName="currency"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6">
                  </div>
                </div>

                <div class="sm:col-span-2">
                  <label for="regular_price" class="block text-sm font-medium leading-6 text-gray-900">
                    Price
                  </label>
                  <div class="mt-2">
                    <input formControlName="regular_price" type="number" name="regular_price" id="regular_price" min="1"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6">
                  </div>
                </div>

                <div class="sm:col-span-full">
                  <label for="stock_quantity" class="block text-sm font-medium leading-6 text-gray-900">
                    Quantity
                  </label>
                  <div class="mt-2">
                    <input id="stock_quantity" name="stock_quantity" type="number" formControlName="stock_quantity"
                      min="1"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6">
                  </div>
                </div>

                <div class="col-span-full">
                  <div class="relative flex gap-x-3">
                    <div class="flex h-6 items-center">
                      <input id="mostPopular" name="mostPopular" type="checkbox" formControlName="mostPopular"
                        class="h-4 w-4 rounded border-gray-300 text-main focus:ring-main">
                    </div>
                    <div class="text-sm leading-6">
                      <label for="mostPopular" class="font-medium text-gray-900">
                        Most Popular
                      </label>
                      <p class="text-gray-500">Most popular ticket</p>
                    </div>
                  </div>
                </div>

                <div class="col-span-full mt-3">
                  <div class="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button [disabled]="!ticketForm.valid" type="submit"
                      class="inline-flex w-full items-center justify-center rounded-md bg-main px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main sm:col-start-2">
                      <svg *ngIf="saving" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                      </svg>
                      Save
                    </button>
                    <button type="button" (click)="toggleModal()"
                      class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
