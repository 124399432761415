<div class="isolate mx-auto grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
    <div class="col-start-1 md:col-start-2 rounded-3xl p-8 xl:p-10 ring-2 ring-main">
        <div class="flex items-center justify-between gap-x-4">
            <h3 id="tier-startup" class="text-lg font-semibold leading-8 text-main">{{ ticket.name }}</h3>
            <p *ngIf="isMostPopular" class="rounded-full bg-main/10 px-2.5 py-1 text-xs font-semibold leading-5 text-main">Most popular</p>
        </div>
        <p class="mt-4 text-sm leading-6 text-gray-600">{{ description }}</p>
        <p class="mt-6 flex items-baseline gap-x-1">
            <span class="text-4xl font-bold tracking-tight text-gray-900">{{ getCurrency() }}{{ ticket.regular_price }}</span>
            <!-- Payment frequency, update based on frequency toggle state
            <span class="text-sm font-semibold leading-6 text-gray-600">/month</span> -->
        </p>
        <a aria-describedby="tier-startup"
            class="mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main bg-main text-white hover:text-white shadow-sm hover:bg-main/70">
            Get access</a>
        <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
            <li *ngFor="let item of whatsIncluded" class="flex gap-x-3">
                <svg class="h-6 w-5 flex-none text-main" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd" />
                </svg>
                {{ item }}
            </li>
        </ul>
    </div>
</div>
