import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Form } from 'src/app/shared/model/form/form.model';
import { LegalLinks } from 'src/app/shared/model/form/legal-link.model';
import { FormService } from 'src/app/shared/service/form.service';

@Component({
  selector: 'form-legal-links',
  templateUrl: './form-legal-links.component.html',
  styleUrls: ['./form-legal-links.component.scss']
})
export class FormLegalLinksComponent implements OnInit {
  @Input('form') form: Form;
  legalLinksForm: FormGroup;
  isSaving: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private FormService: FormService,) { }

  ngOnInit(): void {
    this.initLegalLinksForm();
  }

  private initLegalLinksForm(): void {
    this.legalLinksForm = this.formBuilder.group({
      privacyPolicy: [this.form?.legalLinks?.privacyPolicy || '', [Validators.required, this.urlValidator]],
      termsOfService: [this.form?.legalLinks?.termsOfService || '', [Validators.required, this.urlValidator]],
      refundPolicy: [this.form?.legalLinks?.refundPolicy || '', [Validators.required, this.urlValidator]]
    });
  }

  private urlValidator(control: any): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !value.startsWith('https://')) {
      return { invalidUrl: true };
    }
    return null;
  }

  onSubmit(): void {
    if (this.legalLinksForm.invalid) return;
    this.isSaving = true;
    const legalLinks: LegalLinks = this.legalLinksForm.value;

    this.FormService
      .saveLegalLinks(legalLinks, this.form)
      .subscribe({
        next: form => {
          this.form.legalLinks = form.legalLinks,
            this.isSaving = false;
        },
        error: _ => {
          this.isSaving = false;
        }
      });
  }
}
