import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { AnalyticsModule } from '../analytics/analytics.module';
import { AttendeesModule } from '../attendees/attendees.module';
import { IconModule } from '../icon/icon.module';
import { MeetingModule } from '../meeting/meeting.module';
import { NotificationsModule } from '../notifications/notifications.module';
import { RegistrationModule } from '../registration/registration.module';
import { SharedModule } from '../shared/shared.module';
import { ConfigurationComponent } from './event-configuration/configuration/configuration.component';
import { EventConfigurationComponent } from './event-configuration/event-configuration.component';
import { EventEditorRoutingModule } from './event-editor-routing.module';
import { EventEditorComponent } from './event-editor.component';
import { BasicInformationComponent } from './event-information/basic-information/basic-information.component';
import { EventBrochuresComponent } from './event-information/event-brochures/event-brochures.component';
import { EventCoverComponent } from './event-information/event-cover/event-cover.component';
import { EventIndustriesComponent } from './event-information/event-industries/event-industries.component';
import { EventInformationComponent } from './event-information/event-information.component';
import { EventLocationComponent } from './event-information/event-location/event-location.component';
import { EventNatureComponent } from './event-information/event-nature/event-nature.component';
import { EventScheduleComponent } from './event-information/event-schedule/event-schedule.component';
import { EventSponsorsComponent } from './event-information/event-sponsors/event-sponsors.component';
import { EventTypeComponent } from './event-information/event-type/event-type.component';
import { LastUpdateShortcutsComponent } from './event-information/last-update-shortcuts/last-update-shortcuts.component';
import { BasicStatsComponent } from './event-information/overview/basic-stats/basic-stats.component';
import { OverviewComponent } from './event-information/overview/overview.component';
import { PaymentGatewayConfigurationComponent } from './event-information/payment-gateway-configuration/payment-gateway-configuration.component';
import { EventSettingComponent } from './event-setting/event-setting.component';
import { SettingComponent } from './event-setting/setting/setting.component';
import { NavBarMenuComponent } from './layout/nav-bar-menu/nav-bar-menu.component';
import { ShareEventComponent } from './layout/share-event/share-event.component';
import { DesktopMenuComponent } from './layout/side-bar-menu/desktop-menu/desktop-menu.component';
import { MenuComponent } from './layout/side-bar-menu/menu/menu.component';
import { SideBarMenuComponent } from './layout/side-bar-menu/side-bar-menu.component';
import { UpdateEventDetailsComponent } from './update-event-details/update-event-details.component';

@NgModule({
  declarations: [
    EventEditorComponent,
    SideBarMenuComponent,
    NavBarMenuComponent,
    DesktopMenuComponent,
    MenuComponent,
    EventInformationComponent,
    BasicInformationComponent,
    EventCoverComponent,
    EventLocationComponent,
    EventScheduleComponent,
    EventConfigurationComponent,
    ConfigurationComponent,
    EventSettingComponent,
    SettingComponent,
    EventNatureComponent,
    EventTypeComponent,
    EventIndustriesComponent,
    ShareEventComponent,
    EventSponsorsComponent,
    EventBrochuresComponent,
    OverviewComponent,
    BasicStatsComponent,
    UpdateEventDetailsComponent,
    LastUpdateShortcutsComponent,
    PaymentGatewayConfigurationComponent
  ],
  imports: [
    CommonModule,
    EventEditorRoutingModule,
    RegistrationModule,
    AttendeesModule,
    AnalyticsModule,
    SharedModule,
    NzDatePickerModule,
    ClipboardModule,
    MeetingModule,
    NotificationsModule,
    IconModule,
  ]
})
export class EventEditor { }
