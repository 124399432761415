<button type="button" (click)="toggleModal()"
  class="inline-flex items-center gap-x-2 rounded-md bg-main px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
  New Question
  <svg class="-mr-0.5 size-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
    stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>
</button>


<div @opacity *ngIf="isModal" class="relative z-[999]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-[999] overflow-y-auto">
    <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
      <div @opacityTranslateY *ngIf="isModal"
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
        <form [formGroup]="questionform" (submit)="onSubmit()">
          <div class="space-y-12">
            <div class>
              <div *ngIf="!editMode">
                <h2 class="text-base font-semibold leading-7 text-slate-900">Add Question</h2>
                <p class="mt-1 text-sm leading-6 text-slate-600">Create a new question to add to your form.</p>
              </div>

              <div class="mt-5 grid grid-cols-1 sm:grid-cols-4 gap-x-2 gap-y-5">
                <div class="col-span-full">
                  <label for="question" class="questionformblock text-sm font-medium leading-6 text-gray-900">
                    Question
                  </label>
                  <div class="mt-2">
                    <input formControlName="question" type="text" name="question" id="question"
                      placeholder="Enter the question"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6">
                  </div>
                </div>

                <div class="col-span-full">
                  <label for="answer" class="block text-sm font-medium leading-6 text-gray-900">
                    Answer
                  </label>
                  <div class="mt-2">
                    <textarea id="answer" name="answer" formControlName="answer" rows="3"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6"></textarea>
                  </div>
                </div>

                <div class="col-span-full mt-3">
                  <div class="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button [disabled]="!questionform.valid" type="submit"
                      class="inline-flex w-full items-center justify-center rounded-md bg-main px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main sm:col-start-2">
                      <svg *ngIf="saving" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                      </svg>
                      Save
                    </button>
                    <button type="button" (click)="toggleModal()"
                      class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
